import React from 'react'
import PropTypes from 'prop-types'

import DialogModal from '../DialogModal/dynamic'
import ModalUserManagement from '../ModalUserManagement/dynamic'

import { withTranslation } from '../../../i18n'

import UserRowCardStyled from './styled'
import EditIconImg from '../../assets/images/user-row-card/edit.svg'
import DeleteIconImg from '../../assets/images/user-row-card/delete.svg'
import ActiveStatusIconImg from '../../assets/images/user-row-card/active-status.svg'

export class UserRowCard extends React.PureComponent {
  state = {
    showDeleteUserModal: false,
    showEditUserModal: false,
  }

  getStatusIcon() {
    const activeIcon = (
      <div id={`${this.props.id}-status-icon`} data-test={`${this.props.id}-status-icon`} className="user-status-icon">
        <img src={ActiveStatusIconImg} />
      </div>
    )
    return <div className="user-status-icon-container">{this.props.userData.emailVerified ? activeIcon : null}</div>
  }

  getEmail() {
    return (
      <div id={`${this.props.id}-email`} data-test={`${this.props.id}-email`} className="user-email-container" title={this.props.userData.email}>
        {this.props.userData.email}
      </div>
    )
  }

  getUserDetail() {
    return (
      <div className="user-detail-container">
        {this.getStatusIcon()}
        {this.getEmail()}
      </div>
    )
  }

  getOptionsPanel() {
    const editButton = (
      <div
        id={`${this.props.id}-edit-icon`}
        data-test={`${this.props.id}-edit-icon`}
        className="icon-container"
        onClick={this.handleShowEditUserModal}
      >
        <img src={EditIconImg} />
      </div>
    )
    const deleteButton = (
      <div
        id={`${this.props.id}-delete-icon`}
        data-test={`${this.props.id}-delete-icon`}
        className="icon-container"
        onClick={this.handleShowDeleteUserModal}
      >
        <img src={DeleteIconImg} />
      </div>
    )
    return (
      <div className="user-option-panel-container">
        {this.props.onUpdateUser ? editButton : null}
        {this.props.onDeleteUser ? deleteButton : null}
      </div>
    )
  }

  handleShowEditUserModal = () => {
    this.setState({
      showEditUserModal: true,
    })
  }

  handleCloseEditUserModal = () => {
    this.setState({
      showEditUserModal: false,
    })
  }

  handleCloseDeleteUserModal = () => {
    this.setState({
      showDeleteUserModal: false,
    })
  }

  handleShowDeleteUserModal = () => {
    this.setState({
      showDeleteUserModal: true,
    })
  }

  handleDeleteUserBtnClicked = () => {
    this.props.onDeleteUser(this.props.userData)
    this.handleCloseDeleteUserModal()
  }

  getDeleteUserConfirmModal() {
    let output = null
    if (this.state.showDeleteUserModal) {
      output = (
        <DialogModal
          id="delete-user-dialog"
          className=""
          title={this.props.t('delete_user')}
          content={this.props.t('are_you_sure_you_want_to_delete_this_user')}
          imgSrc={DeleteIconImg}
          primaryBtnOnClick={this.handleDeleteUserBtnClicked}
          primaryBtnText={this.props.t('delete')}
          secondaryBtnOnClick={this.handleCloseDeleteUserModal}
          secondaryBtnText={this.props.t('cancel')}
        />
      )
    }
    return output
  }

  handleUpdateUserClicked = userData => {
    this.props.onUpdateUser(userData, this.props.userData.roleId)
    this.handleCloseEditUserModal()
  }

  getEditUserModal() {
    let output = null
    if (this.state.showEditUserModal) {
      output = (
        <ModalUserManagement
          id="update-user-management-modal"
          roleOptions={this.props.roleOptions}
          userData={this.props.userData}
          onClose={this.handleCloseEditUserModal}
          onSubmit={this.handleUpdateUserClicked}
        />
      )
    }
    return output
  }

  render() {
    return (
      <UserRowCardStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        {this.getUserDetail()}
        {this.getOptionsPanel()}
        {this.getEditUserModal()}
        {this.getDeleteUserConfirmModal()}
      </UserRowCardStyled>
    )
  }
}

UserRowCard.defaultProps = {
  id: 'user-row-card',
  className: '',
  roleOptions: [],
  isCurrentUser: false,
  isAdmin: false,
}

UserRowCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
  userData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    emailVerified: PropTypes.bool,
    email: PropTypes.string.isRequired,
    roleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  onUpdateUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(UserRowCard)
