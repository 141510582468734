import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 19px 125px 19px 45px;
  background-color: #242424;
  margin-bottom: 4px;
  .user-detail-container {
    display: flex;
    align-items: center;
    width: 416px;
    .user-status-icon-container {
      width: 79px;
      padding-right: 64px;
      .user-status-icon {
        display: flex;
        width: 15px;
        height: 18px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .user-email-container {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 500;
      color: #fafafa;
    }
  }
  .user-option-panel-container {
    display: flex;
    align-items: center;
    .icon-container {
      display: flex;
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin: 0 6px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .alert-modal-container {
    .alert-content-container {
      white-space: nowrap;
    }
  }
  @media only screen and (min-width: ${device.minSizeL}) and (max-width: ${device.maxSizeL}) {
    padding: 19px 89px 19px 29px;
    .user-detail-container {
      .user-status-icon-container {
        width: 60px;
        padding-right: 45px;
        .user-status-icon {
          display: flex;
          width: 15px;
          height: 18px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    padding: 19px 103px 19px 37px;
    .user-detail-container {
      .user-status-icon-container {
        width: 65px;
        padding-right: 50px;
        .user-status-icon {
          display: flex;
          width: 15px;
          height: 18px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${device.maxSizeS}) {
    padding: 19px 64px 19px 21px;
    .user-detail-container {
      .user-status-icon-container {
        width: 41px;
        padding-right: 26px;
        .user-status-icon {
          display: flex;
          width: 15px;
          height: 18px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
`
